<template>
	<div class="container-fluid child-view child-view">
		<div class="row justify-content-between mb-4">
			<div class="col-sm">
				<h1>Flex Forms</h1>
				<p class="lead text-muted">
					A list of form templates you can use to save new form data.
				</p>
			</div>
			<!--			<div class="col-auto mr-auto">-->
			<!--				<a href="" class="btn btn-lg btn-outline-primary"-->
			<!--					><i class="material-icons">refresh</i>Refresh-->
			<!--				</a>-->
			<!--			</div>-->
		</div>
		<section>
			<div class="table-responsive">
				<table class="table table-borderless">
					<thead>
						<tr>
							<th scope="col">Id</th>
							<th scope="col">Form Templates</th>

							<th scope="col" class="text-right pr-3">Action</th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="selfUser && (selfUser.rtnow_rt || userCanSee.assessment)">
							<td>&nbsp;</td>
							<td>Patient Assessment Form</td>
							<td class="d-flex justify-content-end">
								<div
									data-toggle="buttons"
									class="btn-group-toggle text-right ml-1 d-flex"
								>
									<router-link
										class="btn -text-light btn-warning"
										:to="'/assessment/new/init'"
										><i class="material-icons -lime-text"
											>subdirectory_arrow_right</i
										>New Assessment</router-link
									>
								</div>
							</td>
						</tr>
						<tr
							class=""
							v-for="row in available_forms"
							:key="'available-forms-' + row.id"
						>
							<td>{{ row.id }}</td>
							<td>{{ row.name }}</td>

							<td class="d-flex justify-content-end">
								<div
									data-toggle="buttons"
									class="btn-group-toggle text-right ml-1 d-flex"
								>
									<router-link
										class="btn -text-light btn-success"
										:to="{ path: '/form/init/' + row.id }"
										><i class="material-icons -lime-text"
											>subdirectory_arrow_right</i
										>New Submission</router-link
									>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>
		<pre>available_forms: {{ available_forms }}</pre>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	name: "FlexForm-ListForms",
	data() {
		return {
			available_forms: []
		};
	},
	methods: {
		...mapActions(["ajax"]),

		getList() {
			this.ajax({
				url: "/flex_form/forms",
				data: {},
				success: json => {
					this.available_forms = json.available_forms;
				}
			});
		}
	},

	computed: {
		...mapGetters(["selfUser"])
	},
	mounted() {
		this.getList();
	}
};
</script>

<style scoped></style>
