<template>
	<section>
		<form
			class="container pb-4 mb-4"
			style="max-width: 720px;"
			@submit.prevent="submitForm(false)"
		>
			<!--		<pre-->
			<!--			style="display: none; opacity: 1; max-height: 1000vh; margin-bottom: 20px;"-->
			<!--			>{{ formTemplate }}</pre-->
			<!--		>-->

			<div class="row">
				<h1 class="col-12">{{ formTemplate.formTitle }}</h1>
			</div>

			<div
				class="row my-4"
				v-for="group in formTemplate.fieldGroups"
				:key="group.groupId"
			>
				<h4 v-if="group.groupTitle" class="col-12 py-4">
					{{ group.groupTitle }}
				</h4>

				<div class="form-group col" v-for="f in group.fields" :key="f.field">
					<label :for="f.field" class="form-label"
						>{{ f.label }}
						<span v-if="f.instructions" class="text-muted"
							>({{ f.instructions }})</span
						></label
					>

					<div v-if="f.type === 'date'" class="wrap_datepicker">
						<datepicker
							:id="f.field"
							:name="f.field"
							v-model="f.value"
							@closed="f.error = false"
							:format="'MM/dd/yyyy'"
							:input-class="{
								'border-danger': !!f.error,
								'form-control': true,
								'form-control-lg': true
							}"
						>
						</datepicker>
						<label class="datepicker_svg" :for="f.field">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
								/>
							</svg>
						</label>
					</div>

					<input
						v-if="f.type === 'text'"
						type="text"
						class="form-control form-control-lg"
						:class="{ 'border-danger': !!f.error }"
						:id="f.field"
						:name="f.field"
						:placeholder="f.label"
						v-model="f.value"
						@blur="f.error = false"
					/>

					<textarea
						v-if="f.type === 'textarea'"
						class="form-control form-control-lg"
						:class="{ 'border-danger': !!f.error }"
						:id="f.field"
						:name="f.field"
						:placeholder="f.label"
						style="height: 190px;"
						v-model="f.value"
						@blur="f.error = false"
					></textarea>

					<select
						v-if="f.type === 'select'"
						class="custom-select form-control-lg "
						:class="{ 'border-danger': !!f.error }"
						:id="f.field"
						:name="f.field"
						:placeholder="f.label"
						v-model="f.value"
						@change="f.error = false"
					>
						<option value="">- Select {{ f.label }} -</option>
						<option
							:key="f.field + '-' + o.value"
							v-for="o in f.options"
							:value="o.value"
							>{{ o.display }}</option
						>
					</select>

					<div v-if="f.type === 'radio'">
						<div
							class="custom-control custom-radio mt-2 mb-1"
							v-for="o in f.options"
							:key="o.key"
						>
							<input
								:id="f.field + o.value"
								:name="f.field"
								type="radio"
								class="custom-control-input "
								checked=""
								required=""
								:value="o.value"
								v-model="f.value"
								@change="f.error = false"
							/>
							<label class="custom-control-label" :for="f.field + o.value">{{
								o.display
							}}</label>
						</div>
					</div>

					<div v-if="f.type === 'checkbox'">
						<div v-if="typeof f.value !== 'object'" class="alert alert-danger">
							Field value must be an empty array.
						</div>

						<div
							class="custom-control custom-checkbox mt-2 mb-1"
							v-for="(o, i) in f.options"
							:key="o.key"
						>
							<input
								:id="f.field + '--' + i"
								:name="f.field"
								type="checkbox"
								class="custom-control-input "
								:value="o.value"
								v-model="f.value"
								@change="f.error = false"
							/>
							<label class="custom-control-label" :for="f.field + '--' + i">{{
								o.display
							}}</label>
						</div>
					</div>

					<!--					<div v-if="f.type === 'checkbox'">-->
					<!--						<pre style="display: block !important;">{{ f }}</pre>-->

					<!--						checkbox!!!!!-->
					<!--						<div-->
					<!--							class="custom-control custom-checkbox mt-2 mb-1"-->
					<!--							v-for="(o, i) in f.options"-->
					<!--							:key="o.key"-->
					<!--						>-->
					<!--							<input-->
					<!--								:id="f.field + '&#45;&#45;' + i"-->
					<!--								:name="f.field"-->
					<!--								type="checkbox"-->
					<!--								class="custom-control-input "-->
					<!--								:value="o.value"-->
					<!--								v-model="f.value"-->
					<!--							/>-->
					<!--							<label class="custom-control-label" :for="f.field + '&#45;&#45;' + i">{{-->
					<!--									o.display-->
					<!--								}}</label>-->
					<!--						</div>-->
					<!--					</div>-->

					<div v-if="!!f.error" class="text-danger pt-2">{{ f.error }}</div>
				</div>
			</div>

			<section class="row">
				<div class="col-12 mb-4 mt-2 pb-4">
					<!--					<button-->
					<!--						v-if="showIframe"-->
					<!--						class="w-100 btn btn-secondary btn-lg mb-4 "-->
					<!--						type="submit"-->
					<!--						@click.prevent="submitForm(true)"-->
					<!--					>-->
					<!--						Preview Fax-->
					<!--					</button>-->
					<button
						class="w-100 btn btn-primary btn-lg mb-4 "
						type="submit"
						@click.prevent="submitForm(false)"
					>
						{{ formTemplate.submitLabel }}
					</button>
				</div>
			</section>

			<pre style=""> data: {{ formTemplate }} </pre>
		</form>
		<iframe
			v-if="showIframe"
			height="10000"
			width="100%"
			id="myIFrame"
		></iframe>

		<app-modal :modal="basicAlertModal" @close-modal="closeModal">
			<!--			<template slot="title">.</template>-->

			<form>
				<p style="margin-top: 1rem;">{{ basicAlertModal.message }}</p>
			</form>

			<template slot="footer">
				<button
					v-if="showBackToFormListButton"
					type="button"
					class="btn btn-outline-primary"
					data-dismiss="modal"
					v-on:click.prevent="backToFormList()"
				>
					OK
				</button>
				<button
					v-else
					type="button"
					class="btn btn-outline-secondary"
					data-dismiss="modal"
					v-on:click.prevent="basicAlertModal.show = false"
				>
					Try Again
				</button>
			</template>
		</app-modal>
	</section>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { mapActions, mapMutations } from "vuex";

export default {
	name: "FlexForm-Init",

	components: {
		Datepicker
	},

	data() {
		return {
			showIframe: true, // for fax preview testing
			showBackToFormListButton: false,
			basicAlertModal: {
				show: false,
				message: "Message here",
				id: "basicAlertModal"
			},
			formTemplate: {
				formTitle: "Loading..."
			},
			oldFormTemplate: {
				formTitle: "This is the Form Title",
				submitLabel: "Save",

				fieldGroups: [
					{
						//groupTitle: "Group Title",
						groupId: 1,
						fields: [
							{
								//
								field: "facilityId",
								label: "Facility",
								rules: "required",
								type: "select",
								value: "",
								options: [
									{ string: "Alpha", value: 111 },
									{ string: "Beta", value: 222 },
									{ string: "Gamma", value: 333 },
									{ string: "Delta", value: 444 },
									{ string: "Bob", value: 555 }
								]
							}
						]
					},
					{
						groupTitle: "Patient Details",
						groupId: 2,
						fields: [
							{
								field: "patientIdentifier",
								label: "Patient Identifier #",
								type: "text",
								rules: "trim"
							}
						]
					},
					{
						groupId: 3,

						fields: [
							{
								field: "patientFirstName",
								label: "Patient First Name",
								type: "text",
								rules: "trim"
							},
							{
								field: "patientLastName",
								label: "Patient Last Name",
								type: "text",
								rules: "trim"
							}
						]
					},
					{
						groupId: 4,
						fields: [
							{
								field: "patientBirthday",
								label: "Patient Birthday",
								instructions: "MM/DD/YYYY",
								type: "text",
								rules: "trim"
							}
						]
					},
					{
						groupId: 5,
						fields: [
							{
								field: "startDate",
								label: "Start Date",
								type: "date",
								rules: "trim",
								value: ""
							},
							{
								field: "startTime",
								label: "Start Time",
								type: "text",
								rules: "trim"
							}
						]
					},
					{
						groupId: 6,
						fields: [
							{
								field: "endDate",
								label: "End Date",
								type: "date",
								rules: "trim",
								value: ""
							},
							{
								field: "endTime",
								label: "End Time",
								type: "text",
								rules: "trim"
							}
						]
					},
					{
						groupId: 7,
						groupTitle: "Notes",
						fields: [
							{
								field: "purposeOfCall",
								label: "Purpose of Call",
								type: "textarea",
								rules: "trim"
							}
						]
					},
					{
						groupId: 8,
						fields: [
							{
								field: "typeEquipmentTroubleshooting",
								label: "Type of Equipment Involved in Troubleshooting",
								type: "textarea",
								rules: "trim"
							}
						]
					},
					{
						groupId: 9,
						fields: [
							{
								field: "descriptionOfIssue",
								label: "Description of Issue",
								type: "textarea",
								rules: "trim"
							}
						]
					},
					{
						groupId: 10,
						fields: [
							{
								field: "resolution",
								label: "Resolution",
								type: "textarea",
								rules: "trim"
							}
						]
					},
					{
						groupId: 11,
						fields: [
							{
								field: "driverDispatched",
								label: "Driver Dispatched?",
								type: "radio",
								rules: "trim",
								options: [
									{ key: "YES", value: "yes" },
									{ key: "NO", value: "no" }
								]
							},
							{
								field: "respiratoryTherapistDispatched",
								label: "Respiratory Therapist Dispatched?",
								type: "radio",
								rules: "trim",
								options: [
									{ key: "YES", value: "yes" },
									{ key: "NO", value: "no" }
								]
							}
						]
					}
				]
			} // formTemplate
		};
	},

	mounted() {
		this.initForm();
	},

	methods: {
		...mapActions(["ajax"]),
		...mapMutations(["setAjaxLoader"]),

		backToFormList() {
			this.$router.push("/form/list");
		},

		closeModal() {
			this.basicAlertModal.show = false;
		},
		submitForm(preview) {
			this.setAjaxLoader(true);
			// eslint-disable-next-line no-undef
			$.ajax({
				//url:,
				url: preview ? "/api/flex_form/preview/" : "/api/flex_form/submit",
				type: "POST",
				data: JSON.stringify({ form_data: this.formTemplate }),
				contentType: "application/json",
				// eslint-disable-next-line no-unused-vars
				success: json => {
					this.setAjaxLoader(false);

					if (json.success) {
						//
						this.basicAlertModal.show = true;
						this.basicAlertModal.message = "Your form data was saved.";
						this.basicAlertModal.showBackToFormListButton = true;

						this.$router.push("/form/list");
					}
					// error
					else {
						this.basicAlertModal.show = true;
						this.basicAlertModal.message = json.message;
						this.basicAlertModal.showBackToFormListButton = false;
						this.formTemplate = json.form_data;
					}

					//
					// for preview
					if (preview && this.showIframe) {
						let iframe = document.getElementById("myIFrame");
						iframe.src =
							"data:text/html;charset=utf-8," + encodeURI(json.html_preview);
					}
				}
			});
		},
		initForm() {
			// eslint-disable-next-line no-undef
			$.ajax({
				url: "/api/flex_form/init/" + this.$route.params.formTemplateId,

				type: "POST",
				//data: JSON.stringify({ data: this.formTemplate }),
				//contentType: "application/json",
				// eslint-disable-next-line no-unused-vars
				success: json => {
					console.log("-------json", json);
					this.formTemplate = json.form_data;
				}
			});
		}
	}
};
</script>

<style lang="scss">
.wrap_datepicker {
	position: relative;
	.datepicker_svg {
		width: 24px;
		height: 24px;
		position: absolute;
		top: 50%;
		right: 10px;
		margin-top: -12px;
		opacity: 0.6;
	}

	.vdp-datepicker {
		.form-control {
			background-color: white;
		}
	}
}

#basicAlertModal {
	.modal-dialog {
		max-width: 400px;
	}
}
</style>
